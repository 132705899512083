@tailwind base;
@tailwind components;
@tailwind utilities;

/* Background animation */
.background-animate {
  background-size: 400%;

  -webkit-animation: BackgroundGradient 8s ease infinite;
  -moz-animation: BackgroundGradient 8s ease infinite;
  animation: BackgroundGradient 8s ease infinite;
}

@keyframes BackgroundGradient {

  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.animate-rotate-360 {
  -webkit-animation: AnimateRotate360 0.5s ease-in infinite;
  -moz-animation: AnimateRotate360 0.5s ease-in infinite;
  animation: AnimateRotate360 0.5s ease-in infinite;
}

@keyframes AnimateRotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-invisible::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-invisible {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.text-8xl {
  font-size: 6rem;
  /* 96px */
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  /* 72px */
  line-height: 1;
}

.text-5xl {
  font-size: 3rem;
  /* 48px */
  line-height: 1;
}

.text-3xl {
  font-size: 1.875rem;
  /* 30px */
  line-height: 2.25rem;
  /* 36px */
}

/* // <uniquifier>: Use a unique and descriptive class name */
/* // <weight>: Use a value from 160 to 700 */

html {
  font-family: "Readex Pro", sans-serif !important;
  scroll-behavior: smooth;
}

text {
  font-family: "Readex Pro", sans-serif !important;
}

.readex-pro-text {
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings:
    "HEXP" 0;
}